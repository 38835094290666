

jQuery(function() {

  var swiper = new Swiper('.swiper-container', {
  // effect: 'fade',
  loop: true,
  autoplay: {
      delay: 2500
  },
  pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
  }
  });

    var formDetails = {
        'firstName': '',
        'lastName': '',
        'username': '',
        'email': '',
        'description': '',
        'title': '',
        'category': '',
        'charity': '',
        'byoCharity': {
            'foundationName': '',
            'foundationEIN': '',
            'foundationAddress': '',
            'foundationAddress2': '',
            'foundationCity': '',
            'foundationState': '',
            'foundationZip': ''
        },
        'paypalAddress': ''
    };

    /*

    $("#showBYOCharity").on('change', function() {
        if ($(this).is(":checked") == true) {
            $('#byoCharity').show();
            $('#defaultCharity').hide();
            $('#foundation_name').addClass('required');
            $('#foundation_name').attr('data-error', 'This field is required.');
            $('#foundation_ein').addClass('required');
            $('#foundation_ein').attr('data-error', 'This field is required.');
            $('input[name="Charity"]').removeClass('required');
            $('.foundationDisplay').show();
            $('.charityDisplay').hide();

        } else {
            $('#byoCharity').hide();
            $('#defaultCharity').show();
            $('#foundation_name').removeClass('required');
            $('#foundation_name').removeAttr('data-error');
            $('#foundation_ein').removeClass('required');
            $('#foundation_ein').removeAttr('data-error');
            $('input[name="Charity"]').addClass('required');
            $('.foundationDisplay').hide();
            $('.charityDisplay').show();
        }
    });
    $("#showBYOCharity").trigger("change");
    */

    $("#registration").on('change', function() {
        var form = $(this)[0];

        formDetails.firstName = form[1].value;
        formDetails.lastName = form[2].value;
        formDetails.username = form[3].value;
        formDetails.email = form[4].value;
        formDetails.description = form[5].value;
        formDetails.title = form[6].value;
        formDetails.category = form[7].value;
        formDetails.charity = $('input[name="Charity"]:checked').val();
        formDetails.byoCharity.foundationName = form[21].value;
        formDetails.byoCharity.foundationEIN = form[22].value;
        formDetails.byoCharity.foundationAddress = form[23].value;
        formDetails.byoCharity.foundationAddress2 = form[24].value;
        formDetails.byoCharity.foundationCity = form[25].value;
        formDetails.byoCharity.foundationState = form[26].value;
        formDetails.byoCharity.foundationZip = form[27].value;
        formDetails.paypalAddress = form[31].value;

        $('#firstNameDisplay').html(form[1].value);
        $('#lastNameDisplay').html(form[2].value);
        $('#usernameDisplay').html(form[3].value);
        $('#emailDisplay').html(form[4].value);
        $('#descriptionDisplay').html(form[5].value);
        $('#titleDisplay').html(form[6].value);
        $('#categoryDisplay').html(form[7].value);
        $('#charityDisplay').html($('input[name="Charity"]:checked').val());
        $('#foundationNameDisplay').html(form[21].value);
        $('#foundationEINDisplay').html(form[22].value);
        $('#foundationAddressDisplay').html(form[23].value);
        $('#foundationAddress2Display').html(form[24].value);
        $('#foundationCityDisplay').html(form[25].value);
        $('#foundationStateDisplay').html(form[26].value);
        $('#foundationZipDisplay').html(form[27].value);
        $('#paypalAddressDisplay').html(form[31].value);

    });

});
